/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */
$gutter: 1rem; //for primeflex grid system

@import '../../../node_modules/primeng/resources/primeng.min.css';
@import '../../../node_modules/primeflex/primeflex.scss';
@import '../../../node_modules/primeicons/primeicons.css';
@import '../../../node_modules/prismjs/themes/prism-coy.css';
@import '../../../node_modules/@fullcalendar/core/main.min.css';
@import '../../../node_modules/@fullcalendar/daygrid/main.min.css';
@import '../../../node_modules/@fullcalendar/timegrid/main.min.css';
@import 'assets/demo/flags/flags.css';
